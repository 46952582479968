@import '~antd/dist/antd.css';

body {
  font-family: 'Bebeboo', sans-serif;
}

.main-app {
  max-width: 100%;
}

.flex-root {
  min-height: 100%;
}

.logo-text {
  font-size: 40px;
  color: white;
  font-weight: bold;
}

#kt_login_signin_form_singup_button {
  margin-left: 10px;
}

.modal-menu-show {
  display: flex;
  z-index: 105;
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate(25px, -79.2188px);
}

.height_100 {
  height: 100%;
}


.nav-link.btn-icon {
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 65px !important;
  height: 55px !important;
  font-weight: bold;
}

.nav-link.btn-icon .svg-icon svg {
  margin-bottom: 2px;
  width: 26px !important;
  height: 26px !important;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-popover-message-title {
  font-size: 15px;
  font-weight: 600;
}

.ant-popover-inner .ant-btn.ant-btn-primary.ant-btn-sm {
  font-weight: 500;
  line-height: 1.5;
  border-radius: .65rem;
  outline: 0 !important;
  color: #fff;
  background-color: #20d489;
  box-shadow: none !important;
  border: 0;
}

.ant-popover-inner .ant-btn.ant-btn-sm {
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.65rem;
  outline: 0 !important;
  color: #000;
  background-color: #ffffff;
  box-shadow: none !important;
  border: 0;
}

.ant-popover-buttons {
  margin-bottom: 0;
}

.builder-view .ant-form-item-control-input, .builder-view .ant-col {
  position: initial;
}

.paper-view.widget > div > .ant-tabs-nav {
  display: none;
}

.paper-view > div > .ant-tabs-nav {
  margin: 0 0 8px 0;
}

.site-collapse-custom-panel .ant-collapse-header {
  padding: 8px 16px 12px;
  font-size: 14px;
  font-weight: bold;
}

.site-collapse-custom-panel .ant-collapse-header .anticon-minus-circle {
  text-align: left;
  margin-right: 0;
  color: #000000;
}

.site-collapse-custom-panel .ant-collapse-header .anticon {
  vertical-align: 1.5px !important;
  margin-right: 8px !important;
  width: 12px !important;
}

.form-control.form-control-solid {
  background-color: white;
  border-color: #e7e7e7;
  min-height: 40px;
  padding: 6px 10px;
  font-size: 13px;
}

.ant-picker {
  width: 100%;
  background-color: #fff;
  border-color: #e7e7e7;
  min-height: 40px;
  padding: 6px 10px;
  font-size: 13px;
  border-radius: 0.65rem;
}

.center.paging {
  text-align: center;
}

.center.paging .ant-radio-button-wrapper {
  height: 40px;
  line-height: 40px;
  min-width: 100px;
}

.center.paging .ant-radio-button-wrapper:last-child {
  border-radius: 0 10px 10px 0;
}

.center.paging .ant-radio-button-wrapper:first-child {
  border-radius: 10px 0 0 10px;
}

.loading-table {
  padding: 50px 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #95959552;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

span.anticon.anticon-minus-circle.remove-row {
  width: 100%;
  text-align: end;
  margin-top: 15px;
}

.modal {
  z-index: 5;
}

span.fw-bolder.badge {
  text-transform: capitalize;
}

.form-control[disabled] {
  background-color: #f5f5f5;
}

.upload-item-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 86px;
}

img.upload-item-img {
  /* width: 100%; */
  /* height: 100%; */
  max-width: 100%;
  max-height: 100%;
}

.upload-item-actions {
  flex-direction: column;
  position: absolute;
  top: 22px;
  left: calc(50% - 42px);
  display: none;
}

.upload-item-view {
  width: 104px;
  height: 104px;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  padding: 8px;
  position: relative;
}

.upload-item-actions button {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: #4caf50;
  border: 1px solid transparent;
  font-size: 13px;
  border-radius: 5px;
  margin: 2px 0;
  width: 85px;
}

.upload-item-view:hover div {
  display: flex;
}

button.upload-item-actions-rm {
  background-color: #f44336;
}
.image-widget {
  display: flex;
  align-items: center;
  justify-content: center;
}
